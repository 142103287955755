import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { compose } from 'recompose';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { FormRow } from 'src/ui/form/FormElements';
import styled from 'styled-components';
import AreaLoader from 'src/components/common/AreaLoader';
import { useForm } from 'src/ui/form';
import ValidationError from 'src/ui/ValidationError';
import fundingSourcesStore from 'src/modules/funding-sources/funding-sources-store';
import { useLocationState } from 'src/utils/hooks';
import locations from 'src/billpay/qbdt/pages/funding-source/locations';
import { getOrgId } from 'src/redux/user/selectors';
import { CONSTS, ADD_FUNDING_SOURCE_WIZARD_ORIGIN } from 'src/utils/consts';
import { MIFormattedText } from 'src/utils/formatting';
import useGetFundingSourceDisplayName from 'src/modules/funding-sources/hooks/useGetFundingSourceDisplayName';
import analytics from 'src/services/analytics';
import { AccountType } from 'src/utils/types';
import {
  createAndLinkAccount,
  getBankAccounts,
  getCreditCardAccounts,
  linkAccount,
} from 'src/billpay/qbdt/services/qbdt';
import { withPreservedStateNavigator } from 'src/hoc';
import { QBDTStepLayout } from 'src/billpay/qbdt/components/QBDTStepLayout';
import QBONotificationCard from 'src/components/qbo/QBONotificationCard';
import { billingFee } from 'src/billpay/qbdt/pages/settings/components/billing/locations';
import TruncatedMethodCard from './bank/components/TruncatedMethodCard';
import WizardIntuitAccountSelectField from './bank/components/WizardIntuitAccountSelectField';
import { InitialAddFundingSourceLocationState } from './types';

export type LinkIntuitAccountLocationState = InitialAddFundingSourceLocationState & {
  origin: ADD_FUNDING_SOURCE_WIZARD_ORIGIN | undefined;
  fundingSourceId: number | null;
};

type Props = {
  navigate: (url: string, shouldReplaceCurrent?: boolean, state?: Record<string, any> | null) => void;
  navigateWithPreservedState: (dataToAdd?: Record<string, any>) => void | null | undefined;
  navigateToExitWithPreservedState: (dataToAdd?: Record<string, any>) => void | null | undefined;
  locationState: Record<string, any>;
};

const LinkIntuitAccountToFundingSourcePage = ({
  navigateToExitWithPreservedState,
  navigate,
  navigateWithPreservedState,
  locationState,
}: Props) => {
  const orgId = useSelector(getOrgId);
  const [accounts, setAccounts] = useState<any>();
  const [isLinking, setIsLinking] = useState(false);
  const isIntuitAccountsLoading = !accounts;
  const { getFundingSourceDisplayName } = useGetFundingSourceDisplayName();
  const [fundingSourceId] = useLocationState('fundingSourceId', {});
  const [origin] = useLocationState('origin', '');
  const fundingSource = useSelector(fundingSourcesStore.selectors.byId(fundingSourceId));
  const currentFundingSourceType =
    fundingSource?.fundingType === CONSTS.FUNDING_TYPE.ACH
      ? fundingSource?.fundingType
      : fundingSource?.cardAccount.cardType;
  const fundingSourceDisplayName = getFundingSourceDisplayName({
    fundingSource,
  });

  const goExit = () => {
    if (navigateToExitWithPreservedState) {
      navigateToExitWithPreservedState(locationState);
    }
  };

  const goNext = useCallback(() => {
    if (origin === CONSTS.ADD_FUNDING_SOURCE_WIZARD_ORIGIN.BILLING) {
      navigate(generatePath(billingFee.add, { orgId }), false, {
        newFundingSourceId: fundingSourceId,
      });
    } else if (
      origin === CONSTS.ADD_FUNDING_SOURCE_WIZARD_ORIGIN.SETTINGS &&
      fundingSource.fundingType === CONSTS.FUNDING_TYPE.ACH
    ) {
      navigate(generatePath(locations.bankComplete, { orgId }), false, {
        ...locationState,
        origin,
        newFundingSourceId: fundingSourceId,
      });
    } else if (
      origin === CONSTS.ADD_FUNDING_SOURCE_WIZARD_ORIGIN.PAY_BILL &&
      fundingSource.fundingType === CONSTS.FUNDING_TYPE.CARD &&
      (fundingSource.cardAccount?.network === CONSTS.CARD_PROVIDERS.AMEX ||
        fundingSource.cardAccount?.network === CONSTS.CARD_PROVIDERS.VISA) &&
      navigateToExitWithPreservedState
    ) {
      navigateToExitWithPreservedState(locationState);
    } else if (navigateWithPreservedState) {
      const data =
        fundingSource?.fundingType === CONSTS.FUNDING_TYPE.CARD ? { selectedFundingSourceId: fundingSourceId } : {};
      navigateWithPreservedState(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fireAnalyticsOnAccountLinked = useCallback(() => {
    analytics.trackAction('qbdt-account-connected', {
      type: currentFundingSourceType === 'credit' ? 'Credit card' : 'Bank',
      fundingSourceId,
    });
  }, [currentFundingSourceType, fundingSourceId]);

  useEffect(() => {
    async function loadAccounts() {
      const func = currentFundingSourceType === 'credit' ? getCreditCardAccounts : getBankAccounts;
      const result = await func();
      setAccounts(
        result.map((a) => ({
          Id: a.ListID,
          Name: a.Name,
        }))
      );
    }
    loadAccounts();
  }, [currentFundingSourceType]);

  const onSubmitIntuitAccount = async ({ intuitAccountId }) => {
    if (intuitAccountId) {
      try {
        setIsLinking(true);
        if (intuitAccountId === 'new') {
          await createAndLinkAccount(orgId, fundingSourceId, currentFundingSourceType, fundingSourceDisplayName);
        } else {
          await linkAccount(orgId, fundingSourceId, intuitAccountId);
        }

        fireAnalyticsOnAccountLinked();
        goNext();
      } catch (e) {
        setIsLinking(false);
        const validationErrors = {
          intuitAccountId: 'onboarding.fundingSources.bank.intuitAccounts.errors.exist',
        };
        throw new ValidationError({ validationErrors });
      }
    } else {
      const validationErrors = {
        intuitAccountId: 'onboarding.fundingSources.bank.intuitAccounts.errors.required',
      };
      throw new ValidationError({ validationErrors });
    }
  };
  const model = useMemo(() => ({ intuitAccountId: fundingSource?.intuitAccountId }), [fundingSource]);
  const [intuitAccountVM, { submit }] = useForm(model, {
    submit: onSubmitIntuitAccount,
  });

  const notificationLabel =
    origin === CONSTS.ADD_FUNDING_SOURCE_WIZARD_ORIGIN.PAY_BILL
      ? 'onboarding.fundingSources.bank.intuitAccounts.paymentNotification'
      : 'onboarding.fundingSources.bank.intuitAccounts.notification';
  const { fundingTypeText, nextLabel } = getLabels(fundingSource);

  if (isIntuitAccountsLoading) {
    return <AreaLoader />;
  }

  return (
    <QBDTStepLayout
      title="onboarding.fundingSources.bank.intuitAccounts.title"
      titleValues={{ type: <MIFormattedText label={fundingTypeText} /> }}
      subtitle="onboarding.fundingSources.bank.intuitAccounts.subtitle"
      goExit={goExit}
      onNext={submit}
      nextLabel={nextLabel}
      isLoading={isLinking}
      fullWidthCTA
      hideHeader
    >
      <MainContentContainer>
        {intuitAccountVM.intuitAccountId.value === 'new' && (
          <NotificationContainer>
            <QBONotificationCard
              type={CONSTS.NOTIFICATION_CARD_TYPES.INFO}
              subtitle={{
                label: notificationLabel,
                values: {
                  displayName: fundingSourceDisplayName,
                },
              }}
            />
          </NotificationContainer>
        )}
        <WrappedTruncatedMethodCard method={fundingSource} />
        <FormRow>
          <WizardIntuitAccountSelectField
            fundingSource={fundingSource}
            intuitAccounts={accounts}
            model={intuitAccountVM.intuitAccountId}
          />
        </FormRow>
      </MainContentContainer>
    </QBDTStepLayout>
  );
};

const getLabels = (fundingSource: AccountType) => {
  const fundingSourceType =
    fundingSource?.fundingType === CONSTS.FUNDING_TYPE.ACH
      ? fundingSource?.fundingType
      : fundingSource?.cardAccount.cardType;
  let fundingTypeText = 'onboarding.fundingSources.bank.intuitAccounts.ach';
  let nextLabel = 'onboarding.fundingSources.bank.intuitAccounts.linkAch';
  if (fundingSourceType === CONSTS.CARD_TYPES.DEBIT) {
    fundingTypeText = 'onboarding.fundingSources.bank.intuitAccounts.debitAccount';
    nextLabel = 'onboarding.fundingSources.bank.intuitAccounts.linkDebit';
  } else if (fundingSourceType === CONSTS.CARD_TYPES.CREDIT) {
    fundingTypeText = 'onboarding.fundingSources.bank.intuitAccounts.creditAccount';
    nextLabel = 'onboarding.fundingSources.bank.intuitAccounts.linkCredit';
  }

  return { fundingTypeText, nextLabel };
};

export default compose(withPreservedStateNavigator())(LinkIntuitAccountToFundingSourcePage);

const NotificationContainer = styled.div`
  margin-bottom: 2rem;
`;

const WrappedTruncatedMethodCard = styled(TruncatedMethodCard)`
  margin-bottom: 2rem;
`;

const MainContentContainer = styled.div`
  margin-top: -0.5rem;
  margin-bottom: -3rem;
`;
